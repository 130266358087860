const { gql } = require("@apollo/client");

export const GET_USER_NOTIFICATIONS = gql`
query GETUSERNOTIFICATIONS {
  isProfileComplete {
    unviewedCount
  }
}
`;

export const GET_USER_UNREAD_MESSAGES = gql`
query GETUSERUNREADMESSAGES {
  getUser {
    user {
      id
      name
      firstName
      lastName
      email
      isSuperAdmin
      conversationsSummary {
        unreadCount
      }
      conversations {
        business {
          id
          name
          picture {
            path
          }
          messaging {
          messagingAllowed
          consumerResponseAllowed
          consumerInitiationAllowed
          consumerAttachmentsAllowed
          }
        }
        unreadCount
        recentMessageTime
        recentMessages {
          user {
            id
            firstName
            email
          }
          direction
          business {
            id
            name
            picture {
              path
            }
            messaging {
              messagingAllowed
              consumerResponseAllowed
              consumerAttachmentsAllowed
            }
          }
          messageType
          message {
            user {
              id
            }
            commentText
            createdTime
          }
          attachment {
            contentType
            publicUrl
            jsonData
            fileName
            id
          }
        }
      }
      
    }
    
  }
  isProfileComplete { 
    unviewedCount
  }
}
`;

export const GET_USER_APPOINTMENTS = gql`
    query all($businessId: Int!, $status: String!, $startDate: Date!) {
     
      getCurrentUserAppointments(
        businessId: $businessId
        status: $status
        startDate: $startDate
      ) {
        id
        adults
        children
        allocatedTime
        timeZone
        business {
          id
          name
          description
          address1
          city
          state
          appointmentConfirmationOffset
          isAppointmentConfirmationRequired
          preCheckinInstructions
          lateCheckInTimeThreshold
          categoryTags
          safetyTags
          phone
          webAddress
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
          }
          isFavorite
          serviceRatings {
            ratingType
            value
            reviewsCount
            modifiedTime
          }
        }
        service {
          name
          id
          isGoogleReserve
        }
        subBusiness {
          id
          name
          description
          address1
          appointmentConfirmationOffset
          categoryTags
          safetyTags
          phone
          isFavorite
        }
        comments {
          id
          comment {
            id
            commentText
            createdTime
            modifiedTime
            user {
              id
              name
              firstName
              lastName
              email
            }
          }
          messageDirection
        }
        venueEntrance {
          id
          name
        }
        startTime
        startTimeOnly
        endTime
        status
      }
      isProfileComplete {
        unviewedCount
      }
    }
  `;

export const GET_SAFELYQ_API_OPTIONS = gql`
query safelyqApiOptions{
	safelyqApiOptions{
		mobileAppsUpdateRequired
		allowChatSLB
		searchRadii{
			label
			value
		}
	}
}
`;