import React from 'react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GiEmptyHourglass } from "react-icons/gi";
import { Icon } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

const ConversationFavouritesMobile = ({ conversationFavouritesMobileProps }) => {
    const {
        showStatus,
        setShowStatus,
        selectedConversationId,
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        handleMessagingAllowed,
        handleAppointments,
    } = conversationFavouritesMobileProps;

    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';
    const history = useHistory();
    return (
        <div>
            <div className='is-flex is-justify-content-space-between is-clickable mb-3 custom-tab-dropdown py-1 px-2'
                onClick={() => setShowStatus({ ...showStatus, favouriteBusiness: !showStatus?.favouriteBusiness })}>
                <p className={`is-size-6 has-text-weight-semibold  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`}>
                    Favourites
                </p>
                {showStatus?.favouriteBusiness
                    ?
                    <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />
                    :
                    <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />}
            </div>

            {
                showStatus?.favouriteBusiness &&
                <div className='mb-3' style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%", scrollbarWidth: "thin" }}>
                    {
                        !selectedConversationId ?
                            <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                <GiEmptyHourglass color='gray' fontSize={26} />
                                <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Conversation Selected</p>
                            </div>
                            :
                            favBusinesses?.length === 0
                                ?
                                <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                    <GiEmptyHourglass color='gray' fontSize={26} />
                                    <p className='ml-2 mb-5 has-text-weight-semibold has-text-grey-dark'>No Businesses Found</p>
                                </div>
                                :
                                favBusinesses?.map(({ business }, i) => {
                                    return (
                                        <div
                                            className='card-custom-businesses is-relative'
                                            key={business.id}
                                            style={{ display: "inline-flex", flexDirection: "column", flex: "0 0 33.33%", marginRight: "20px", marginBottom: "10px", width: "140px" }}>
                                            <FavouriteButton className='has-text-info is-clickable'>
                                                <Icon onClick={() =>
                                                    // This is a universal Utility Function
                                                    handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId)}
                                                    name='heart' collection='fa' />
                                            </FavouriteButton>
                                            <img
                                                src={business?.picture?.path || emptyThumbnail}
                                                alt={business.name}
                                                onError={(e) => e.target.src = emptyThumbnail}
                                                className="card-image-custom is-clickable"
                                                onClick={() => history.push(`/business/${business.id}`)}
                                            />
                                            <div className='px-2 my-2 has-text-left'>
                                                <Link to={{ pathname: `/business/${business.id}` }} className='is-size-7 has-text-weight-bold has-text-black' title={business?.name}    >
                                                    {business?.name?.length <= 15 ? business?.name : `${business?.name.substring(0, 15)}...`}
                                                </Link>
                                                <div className='is-flex mt-1'>
                                                    <i className="fa fa-quote-left favorite_icon mx-2 is-size-7 pt-2"
                                                        data-tip="Quotation" />
                                                    <i className="fa fa-calendar favorite_icon mx-1 is-size-6-mobile is-size-6 pt-1"
                                                        onClick={() => handleAppointments(business?.id)}
                                                        data-tip="Appointments" />
                                                    <a
                                                        className='p-0'
                                                        href={business?.email && `mailto:${business.email}?subject=Mail from ${business.name}`}
                                                        onClick={() => !business?.email && toast.warning('Email address is not available for this business.')}
                                                    >
                                                        <i className="fa fa-envelope favorite_icon mx-2 is-size-7"
                                                            data-tip="Mail"
                                                        />
                                                    </a>
                                                    <i className={`fa fa-comments favorite_icon ml-2 is-size-7 pt-2`}
                                                        onClick={() =>
                                                            handleMessagingAllowed(
                                                                business?.id,
                                                                business?.businessSubscription?.subscriptionPlan,
                                                                business?.messaging)
                                                        }
                                                        data-tip="Messaging"
                                                    />
                                                </div>
                                                <ReactTooltip type='dark' effect='solid' />
                                            </div>
                                            <div className='px-2 row g-0'>
                                                <div className='col-lg-12'>
                                                    <p className="business_description is-size-7"> {business.description ? (`${business.description.substring(0, 20)}...`) : ("No Description")}</p>
                                                </div>
                                            </div>
                                            {business.address1 && (
                                                <div className='row px-2 g-0'>
                                                    <div className='col-lg-12'>
                                                        <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                                                        <span className='subtitle is-7'>  {business.city}  </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })

                    }
                </div>
            }

            {/* Divider */}
            <hr className='mt-3' style={{ width: '35%', margin: '10px auto', height: '2px', backgroundColor: '#afafaf', border: 'none' }} />
        </div>
    )
}

export default ConversationFavouritesMobile;

const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 27px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;