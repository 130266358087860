import React, { useEffect, useState } from 'react';
import { RecentConversation, MessageSection } from '../../components';
import MessageMetaTags from './MetaTags/MessageMetaTags';
import AllChats from './AllChats';
import MessageBox from './MessageBox/MessageBox';
import { useDispatch, useSelector } from 'react-redux';
import firestore from '@firebase';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_USER_UNREAD_MESSAGES } from 'components/Common/NavMenu/requests';
import { getAllMessagesWithLatestMessage } from 'Redux/AuthSlice/AuthSlice';
import NewMessageBoxPopup from './MessageBox/NewMessageBoxPopup';



export const ManageUserMessages = () => {
  const [latestMessage, setlatestMessage] = useState({ businessId: "", customerId: "", messageId: "" })

  const [newMessagePopup, setNewMessagePopup] = useState({
    status: false,
    businessId: null,
  })

  const [chatRights, setChatRights] = useState({
    messagingAllowed: false,
    consumerResponseAllowed: false,
    consumerAttachmentsAllowed: false,
    consumerInitiationAllowed: false
  })


  const { allMessages, userinfo } = useSelector((state) => state.auth);
  const isSelectChat = allMessages.some((chat) => chat.active)
  const { pathname, state } = useLocation();
  const dispatch = useDispatch()
  const history = useHistory();

  const { refetch: refetchChat } = useQuery(GET_USER_UNREAD_MESSAGES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {

      dispatch(getAllMessagesWithLatestMessage({
        unreadCount: data.getUser.user.conversationsSummary.unreadCount,
        allMessages: data.getUser.user.conversations,
        totalNotifications: data.isProfileComplete.unviewedCount,
        latestMessage: latestMessage
      }))

    },
  })

  const handleClosePopup = () => {
    setNewMessagePopup({
      status: false,
      businessId: null,
    })
    history.push({
      pathname: '/messages',
      state: undefined
    });
  }

  useEffect(() => {
    if (state) {
      if (state.businessId) {
        setNewMessagePopup({
          status: true,
          businessId: state.businessId,
        })
      }
    }
  }, [state])


  useEffect(() => {
    const unsubscribe = firestore.collection('chat').where('customerId', '==', `${userinfo.id}`).onSnapshot(async querySnapshot => {

      if (String(pathname).includes('/messages') || String(pathname).includes('/search-business-ai')) {
        let allChats = []
        querySnapshot.forEach((doc) => {
          allChats.push(doc.data())
        });
        allChats.sort((a, b) => parseInt(a.messageId) - parseInt(b.messageId));
        let latestMessage = allChats.pop()
        setlatestMessage(latestMessage)
        refetchChat()
      }
    });
    return () => unsubscribe();
  }, [userinfo.id, refetchChat, pathname]);

  return (
    <>
      {newMessagePopup.status && <NewMessageBoxPopup isOpen={newMessagePopup.status} onClose={handleClosePopup} businessId={newMessagePopup.businessId} />}
      <MessageMetaTags />
      <div className='bscontainer' >
        <div className='row'>
          <div className={`col-lg-4 pr-0 ${isSelectChat ? 'is-hidden-mobile' : ''}`} >
            <AllChats />
          </div>
          <div className={`col-lg-8 pl-0 ${!isSelectChat ? 'is-hidden-mobile' : ''}`} style={{ height: "95vh" }}>
            <MessageBox chatRights={chatRights} setChatRights={setChatRights} />
          </div>
        </div>
      </div>
    </>

  );
};

