import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_AVAILABLE_SLOTS } from './request';
import { Skeleton } from 'antd';
import { isEmpty, isEqual, orderBy } from 'lodash';
import { Buttons, Button, Text, Title, Paragraph } from '@safelyq/bulma-ui-library'
import moment from 'moment';

const AppointmentSlots = ({ apptInfo, currentSlot, selectedSlot, onChangeSlot, businessIdFromChatSLB, uniqueSlots, setuniqueSlots }) => {
    const { businessId } = useParams();
    const business = businessId ? parseInt(businessId) : parseInt(businessIdFromChatSLB);

    // let uniqueSlots = useRef([])
    const [loading, setloading] = useState([])


    const [onLoadSlots] = useLazyQuery(GET_AVAILABLE_SLOTS,
        {
            onCompleted: (data) => {
                let dddd = []
                let dumpArray = [];
                const hasOtherSlotsAvailable = !isEmpty(data.getAvailability.others);
                if (!isEmpty(data.getAvailability.first)) dumpArray.push(data.getAvailability.first);
                if (!isEmpty(data.getAvailability.second)) dumpArray.push(data.getAvailability.second);
                if (!isEmpty(data.getAvailability.third)) dumpArray.push(data.getAvailability.third);
                if (!isEmpty(data.getAvailability.fourth)) dumpArray.push(data.getAvailability.fourth);
                if (!isEmpty(data.getAvailability.fifth)) dumpArray.push(data.getAvailability.fifth);
                // check if the appointment is of same business as in current slot and time is also same 
                if ((currentSlot && apptInfo.serviceProvider === currentSlot?.subBusiness?.id) &&
                    moment(apptInfo?.date).isSame(moment(apptInfo?.startTime), 'day'))
                    dumpArray.push(currentSlot);
                if (hasOtherSlotsAvailable) dumpArray = dumpArray.concat(data.getAvailability.others);
                dddd = Array.from(new Set(dumpArray.map((a) => a.startTimeOnly + ':' + a.subBusiness.id))).map((refkey) => { return dumpArray.find((a) => a.startTimeOnly + ':' + a.subBusiness.id === refkey); });
                dddd = orderBy(dddd, 'startTimeOnly', 'asc');
                setuniqueSlots(dddd)
                setloading(false)
            }
        }
    );
    // if (data && data.getAvailability) {
    //     debugger

    // }



    useEffect(() => {

        setloading(true)
        if (apptInfo.service || apptInfo.serviceProvider) {

            onLoadSlots({
                fetchPolicy: 'no-cache',
                variables: {
                    service: apptInfo.service,
                    appointmentDate: apptInfo.date,
                    business: business,
                    appointmentWindow: 'DAY',
                    adults: apptInfo.adults ? parseInt(apptInfo.adults) : 0,
                    subBusinesses: !apptInfo.serviceProvider || parseInt(apptInfo.serviceProvider) === parseInt(apptInfo.service) ? [] : [apptInfo.serviceProvider],
                },

            })

        }
        else {
            setloading(false)
            setuniqueSlots([])
        }
    }, [apptInfo.service, apptInfo.serviceProvider, apptInfo.date, business, onLoadSlots])
    if (loading) {
        return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
    }
    else {
        return (
            <div>


                {/* {uniqueSlots.length > 0 && <Title size='6' color='info' className='mb-4'><i>Available Slots</i><small className='has-text-grey-light'>({apptInfo.timeZone})</small></Title>}
                {uniqueSlots.length === 0 && <Paragraph alignment='centered' weight='bold' color='grey-light'>  No slots available   </Paragraph>}
                <Buttons size='small'>
                    {uniqueSlots.map((slot, index) => {
                        return (
                            <Button onClick={() => onChangeSlot(slot)} light={!isEqual(slot.startTimeOnly, selectedSlot?.startTimeOnly)} color={'info'} key={`slot-${index}`} className='slot-btn' >
                                <Text>{slot.startTimeOnly} </Text>
                            </Button>
                        )
                    })}
                </Buttons> */}


                {apptInfo.service === "" ? <Paragraph alignment='centered' weight='bold' color='grey-light'>  Select a service to see available time slots  </Paragraph> : (
                    <div className='mt-5'>{uniqueSlots.length > 0 && <Title size='6' color='info' className='mb-4'>   <i>Available Slots</i>   <small className='has-text-grey-light'>({apptInfo.timeZone})</small>    </Title>}
                        {uniqueSlots.length === 0 && <Paragraph alignment='centered' weight='bold' color='grey-light'>  No slots available   </Paragraph>}
                        <Buttons size='small'  >
                            {uniqueSlots.map((slot, index) => {
                                return (
                                    <Button onClick={() => onChangeSlot(slot)} light={!isEqual(slot.startTimeOnly, selectedSlot?.startTimeOnly)} color={'info'} key={`slot-${index}`} className='slot-btn' >
                                        <Text>{slot.startTimeOnly} </Text>
                                    </Button>
                                )
                            })}
                        </Buttons>

                    </div>
                )}





            </div>
        )
    }

}

export default AppointmentSlots