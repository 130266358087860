import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useProfile } from 'hooks/useProfile';
import OneSignal from 'react-onesignal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { Button, Icon } from '@safelyq/bulma-ui-library';
import './styles.css';
import { useMarkasViewed } from '../LoginMenu/hooks';
import { getAvatarName } from 'utils/getAvatarName';
import firestore from '@firebase';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_SAFELYQ_API_OPTIONS, GET_USER_APPOINTMENTS, GET_USER_NOTIFICATIONS, GET_USER_UNREAD_MESSAGES } from './requests';
import { addAppointments, getAllMessages, } from 'Redux/AuthSlice/AuthSlice';
import moment from 'moment';
import { APIPaths } from 'services/api-provider';
import { setAllowChatSLB, setSearchRadii } from 'Redux/ConsumerSlice';

const ApplicationPaths = {
  Register: '/account/register',
  Login: '/authentication/login',
  Logout: '/authentication/logout',
  Profile: '/authentication/profile',
};
const NavMenu = () => {
  const { logout, profile, login } = useProfile();

  const globalState = useSelector(state => state);


  // When logging out, change the authPersist state to false
  const handleLogout = () => {
    localStorage.setItem("authPersist", "false")
    logout();
  }

  const { onMarkAsViewed } = useMarkasViewed()
  const { push } = useHistory();

  const { userinfo, authinfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch()
  const [isActive, setActive] = useState(false);

  const allowChatSLB = useSelector(state => state?.consumer?.allowChatSLB);

  const handleMenuClick = useCallback(
    () => setActive((isActive) => !isActive),
    [setActive]
  );
  const handleMenuClose = useCallback(() => setActive(false), [setActive]);
  const { pathname } = useLocation();


  const [loadChat, { refetch: refetchChat }] = useLazyQuery(GET_USER_UNREAD_MESSAGES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {

      dispatch(getAllMessages({
        unreadCount: data.getUser.user.conversationsSummary.unreadCount,
        allMessages: data.getUser.user.conversations,
        totalNotifications: data.isProfileComplete.unviewedCount
      }));
    },
  });

  // Set to the global state and retrieve where ever requried
  const [loadAppointments, { refetch: refetchAppointments }] = useLazyQuery(GET_USER_APPOINTMENTS, {
    variables: { businessId: 0, status: 'ALL', startDate: moment(new Date()).format('YYYY-MM-DD') },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      dispatch(addAppointments({ allAppointments: data.getCurrentUserAppointments, totalNotifications: data.isProfileComplete.unviewedCount }));
    },
  });

  const [loadNotifications, { refetch: refetchNotifications }] = useLazyQuery(GET_USER_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      // handle notifications completion
    },
  });

  const [loadApiOptions, { refetch: refetchApiOptions }] = useLazyQuery(GET_SAFELYQ_API_OPTIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      dispatch(setAllowChatSLB(data?.safelyqApiOptions?.allowChatSLB));
      dispatch(setSearchRadii(data?.safelyqApiOptions?.searchRadii));
    },
  });

  useEffect(() => {

    loadApiOptions();
  }, []); // Empty dependency array to run once on component mount

  const handleLoginWithChatSLB = () => {
    localStorage.setItem('redirectTo', "search-business-ai");
    sessionStorage.clear();
    // localStorage.clear();
    login();
  }

  const attemptLogin = () => {
    sessionStorage.clear();
    localStorage.clear();
    login();
  }
  const attemptRegister = useCallback(
    async (e) => {
      e.preventDefault();
      // window.location.href = `${PrefixHolder.prefixHolder.Gateway}${ApplicationPaths.Register}?returnUrl=${window.location.href}`;
      window.location.href = `${APIPaths.Gateway}${ApplicationPaths.Register}`;
    },
    []//[login]
  );

  const handleMarkAsViewed = (e) => {
    e.preventDefault();
    userinfo?.totalNotifications !== 0 && onMarkAsViewed(); // do not call mark as read if the notifications are already 0
    push('/notifications');
  };

  useEffect(() => {
    const { sub } = profile || {};
    if (OneSignal) {
      if (!sub) {
        try {
          OneSignal.logoutEmail();
          OneSignal.removeExternalUserId();
          OneSignal.logout();
        }
        catch { }
      } else {
        try {
          if (sub) {
            OneSignal.setExternalUserId(sub).then((r) =>
              console.log(`setExternalUserId Response ${r}`)
            );
          }
        } catch (e) {

        }
      }
    }
  }, [profile]);

  useEffect(() => {

    const unsubscribe = firestore.collection('chat')
      .where('customerId', '==', `${userinfo.id}`)
      .onSnapshot(async querySnapshot => {

        if (!String(pathname).includes('/messages') && !String(pathname).includes('/search-business-ai')) {
          // They both have seperate apis and firebase triggers in there component
          loadChat();  // Ensure this line is being executed
        }
      });

    return () => {
      unsubscribe();
    };
  }, [userinfo.id]);

  useEffect(() => {
    const unsubscribe = firestore.collection('appointments').where('customerId', '==', `${userinfo.id}`).onSnapshot(() => {
      pathname !== "/search-business-ai" && loadAppointments(); // this refreshes the chatslb route when appointments update
    });
    return () => unsubscribe();

  }, [userinfo.id, refetchAppointments, pathname]);

  useEffect(() => {

    const unsubscribe = firestore.collection('comments').where('customerId', '==', `${userinfo.id}`).onSnapshot(() => {
      pathname !== "/search-business-ai" && loadAppointments(); // this refreshes the chatslb route when appointments update
    });
    return () => unsubscribe();

  }, [userinfo.id, refetchAppointments, pathname]);


  useEffect(() => {
    const unsubscribe = firestore.collection('ratings').where('customerId', '==', `${userinfo.id}`).onSnapshot(() => {
      if (!String(pathname).includes('/business')) {

        loadNotifications()
      }
    });
    return () => unsubscribe();
  }, [userinfo.id]);


  return (
    <Fragment>
      <section className='section px-1 py-2'>
        <div className='bscontainer'>
          <div className='row'>
            <div className='col-lg-12'>
              <nav className='navbar box p-0' role='navigation' aria-label='main navigation'   >
                <div className='navbar-brand' style={{ width: '100%' }}>
                  <Link to='/' className='navbar-item brand' onClick={handleMenuClose}  >
                    <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt='logo' />
                  </Link>
                  {authinfo ? (
                    <>
                      {
                        allowChatSLB && <NavLink className='navbar-item px-2 is-hidden-mobile' to='/search-business-ai' title='Search' style={{ marginLeft: allowChatSLB && 'auto' }}>
                          <Button size='small' className='button-icon'> <span className='has-text-weight-bold'>  ChatSLB </span>  </Button>
                        </NavLink>
                      }

                      <NavLink className='navbar-item px-2' to='/business/search' title='Search' style={{ marginLeft: !allowChatSLB && 'auto' }}>
                        <Button size='small' className='button-icon'>  <Icon name='search' />  </Button>
                      </NavLink>

                      <NavLink to='/favorites' className='navbar-item px-2' title='Favorites'>
                        <Button size='small' className='button-icon'> <Icon name='heart' />   </Button>
                      </NavLink>

                      <NavLink to='/messages' className='navbar-item px-2' title='Messages'>
                        <Button size='small' className='button-icon'> <Icon name='comments' />  </Button>
                        {userinfo.unreadCount > 0 && (<span className='notification-badge'>  {userinfo.unreadCount > 9 ? '9+' : userinfo.unreadCount}  </span>)}
                      </NavLink>

                      <NavLink to='#' className='navbar-item px-2' title='Notifications'>
                        <Button size='small' className='button-icon' onClick={handleMarkAsViewed}  >  <Icon name='bell' /> </Button>
                        {userinfo.totalNotifications > 0 && (<span className='notification-badge'>  {userinfo.totalNotifications > 9 ? '9+' : userinfo.totalNotifications} </span>)}
                      </NavLink>

                      <NavLink to="/account/setting" className='navbar-item px-2' title='Profile' >
                        <Button size='small' light color='info' className='avatar-icon rounded'>  <strong> {getAvatarName(userinfo)}</strong> </Button>
                        {!userinfo.isPhoneNumberConfirmed && <span className='profile-badge'>!</span>}
                      </NavLink>

                    </>
                  ) : (
                    <>
                      <div className='navbar-item' style={{ marginLeft: 'auto' }}>
                        <div className='buttons'>
                          <button className='button is-small' onClick={attemptRegister}  > Sign Up </button>
                          <button className='button is-outlined is-light is-info mr-2 is-small' onClick={attemptLogin}  >  Sign In  </button>
                          {allowChatSLB && <button className='button is-outlined mr-2 is-small' onClick={handleLoginWithChatSLB}  >  ChatSLB  </button>}
                        </div>
                      </div>
                    </>
                  )}
                  {authinfo && <NavLink className='navbar-item px-2 is-hidden-mobile' to='/' onClick={handleLogout}  > Logout</NavLink>}
                  <span onClick={handleMenuClick} role='button' className={`navbar-burger is-hidden-desktop burger m-0 ${isActive && 'is-active'}`} aria-label='menu' aria-expanded='false' data-target='mainNavbar' >
                    <span aria-hidden='true' />
                    <span aria-hidden='true' />
                    <span aria-hidden='true' />
                  </span>
                </div>
                <div className={'navbar-menu is-hidden-tablet' + (isActive ? ' is-active' : '')}  >
                  <div className='navbar-end'>
                    <Link to='about-us' className='navbar-item' onClick={handleMenuClose} >  About Us  </Link>
                    {authinfo && <NavLink tag={Link} className={`navbar-item`} to='/' onClick={handleLogout} >  Logout </NavLink>}
                    {/* {authinfo && <NavLink tag={Link} className={`navbar-item`} to='/' onClick={logout} >  Logout </NavLink>} */}
                    {allowChatSLB && <NavLink tag={Link} className={`navbar-item pl-3 is-hidden-desktop`} to='/search-business-ai' >  ChatSLB </NavLink>}
                  </div>
                </div>
              </nav>
            </div>
          </div>

        </div>
      </section>
    </Fragment>
  )
}

export default NavMenu