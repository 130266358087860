import { gql } from "@apollo/client";

export const GET_User_CONVERSATION = gql`
  query GetUserConversation {
    getUser {
      user {
        conversations {
          business {
            id
            name
            picture {
              path
            }
          }
          unreadCount
          recentMessageTime
          recentMessages {
            message {
              commentText
            }
          }
        }
      }
    }
  }
`;

export const GET_BUSINESS_CONVERSATION = gql`
  query GetBusinessConversation($businessId: Int!, $conversationId: Int!) {
    getUser {
      user {
        conversationsSummary {
          unreadCount
        }
        
      }
    }
    getBusinessConversation(businessId: $businessId, conversationId: $conversationId) {
      messages {
        user {
          id
          firstName
          email
        }
        direction
        business {
          id
          name
          picture {
            path
          }
        }
        messageType
        message {
          user {
            id
          }
          commentText
          createdTime
        }
        attachment {
          contentType
          publicUrl
          jsonData
          fileName
          id
        }
      }
      
    }
  }
`;


export const GET_USER_UNREAD_MESSAGES_AGAIN = gql`
query GETUSERUNREADMESSAGESAGAIN {
  getUser {
    user {
     
      conversationsSummary {
        unreadCount
      }
      
    }
  }
}
`;

export const SEND_MESSAGE_TO_BUSINESS = gql`
 mutation SendMessageToBusiness($businessMessageInput: BusinessMessageInput) {
    sendMessageToBusiness(businessMessageInput: $businessMessageInput) {
      messageId
      userMessage {
        user {
          id
          email
          firstName
        }
        direction
        business {
          id
          name
          messaging{
            consumerAttachmentsAllowed
            consumerResponseAllowed
            messagingAllowed
          }
          picture {
            path
          }
        }
        message {
          user {
            id
          }
          commentText
          createdTime
        }
        messageType
        attachment {
          contentType
          publicUrl
          jsonData
          fileName
          id
        }
      }
    }
  }
`;