import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAvatarName } from 'utils/getAvatarName';
import { Button, Title, Field, Control, Buttons, } from '@safelyq/bulma-ui-library';
import { useMutation } from '@apollo/client';
import { ADD_COMMENT } from '../request';
import { useParams } from 'react-router-dom';
import { addCommentInAppointment } from 'Redux/AuthSlice/AuthSlice';
import { toast } from 'react-toastify';
import { Spin, } from 'antd';

const AddAppointComment = ({ isAllowComment, apptId, conversationAppointmentsFromChatSLB, updateCommentsInTheState }) => {
    const [newComment, setyNewComment] = useState("")
    const [loading, setLoading] = useState(false)

    let { businessId } = useParams();
    const { userinfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const [onAddComment] = useMutation(ADD_COMMENT, { fetchPolicy: 'no-cache', })

    const handleCommentChange = (e) => {
        setyNewComment(e.target.value)
    }
    const handleAddComment = async () => {
        try {
            setLoading(true)
            const response = await onAddComment({ variables: { commentText: newComment, appointmentId: apptId } });
            const comment = response.data.addAppointmentComment.appointmentComment.comment
            dispatch(addCommentInAppointment({ apptId, newComment: response.data.addAppointmentComment.appointmentComment }))

            // comment is being sent back to the chatslb to update the state
            if (response?.data?.addAppointmentComment?.isSaved && conversationAppointmentsFromChatSLB) {
                updateCommentsInTheState(response.data.addAppointmentComment.appointmentComment)
            }

            toast.success("Comment Added Successfully")
            handleCancel()
        } catch (error) {
            console.log("Error", error);
        } finally {
            setLoading(false)
        }
    }

    const handleCancel = () => {
        setyNewComment("")
    }

    if (!isAllowComment) { return <small className="has-text-danger"> You can't comment on this appointment. </small> }
    return (
        <article className='media'>
            <figure className='media-left'>
                <Button size='small' color='info' className='avatar-icon rounded'>
                    <strong>{getAvatarName(userinfo)}</strong>
                </Button>
            </figure>
            <div className='media-content'>
                <div className='content'>
                    <textarea rows={2} value={newComment} className='textarea' placeholder='Add a comment...' onChange={handleCommentChange} />
                </div>
            </div>
            <Field>
                <Control>
                    {newComment && (
                        <Buttons size='small' className='is-right'>
                            <Spin spinning={loading}>
                                <Button light color='info' disabled={newComment ? false : true} onClick={handleAddComment} >  {loading ? '...' : 'Save'}  </Button>
                            </Spin>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Buttons>
                    )}

                </Control>
            </Field>
        </article>
    )
}

export default AddAppointComment