import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Columns, Column } from '@safelyq/bulma-ui-library';

const Footer = () => {
  return (
    <div>
      <footer className='page-footer'>
        <div className='bscontainer'>
          <div className='row'>
            <div className='col-lg-3 has-text-left-desktop has-text-centered is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-half-widescreen is-half-fullhd'>
              <span className='footer-copyrights'>
                Copyright © {new Date().getFullYear()} &nbsp;
                <Link to='/' className='has-text-white is-underlined'>   SafelyQ   </Link>
              </span>
            </div>
            <div
              className='col-lg-9 has-text-right-desktop has-text-centered is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-half-widescreen is-half-fullhd footer-tags'
            >
              <Link to='/admin' className='has-text-white'>    Admin Login   </Link>
              <span className='mx-3'>|</span>
              <Link to='/for-business' className='has-text-white'>    For Business   </Link>
              <span className='mx-3'>|</span>
              <Link to='/about-us' className='has-text-white'> About Us  </Link>
              <span className='mx-3'>|</span>
              <Link to='/contact-us' className='has-text-white'>   Contact Us  </Link>
              <span className='mx-3'>|</span>
              <Link to='/terms-and-conditions' className='has-text-white'>  Terms and Conditions  </Link>
              <span className='mx-3'>|</span>
              <Link to='/privacy-policy' className='has-text-white'>  Privacy Policy  </Link>
            </div>
          </div>
        </div>

        {/* <Container>
          <Columns>
            <Column size='4' className='has-text-left-desktop has-text-centered is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-half-widescreen is-half-fullhd'>
              <span>
                Copyright © {new Date().getFullYear()} &nbsp;
                <Link to='/' className='has-text-white is-underlined'>   SafelyQ   </Link>
              </span>
            </Column>
            <Column className='has-text-right-desktop has-text-centered'>
              <Link to='/admin' className='has-text-white'>    Admin Login   </Link>
              <span className='mx-3'>|</span>
              <Link to='/about-us' className='has-text-white'> About Us  </Link>
              <span className='mx-3'>|</span>
              <Link to='/contact-us' className='has-text-white'>   Contact Us  </Link>
              <span className='mx-3'>|</span>
              <Link to='/terms-and-conditions' className='has-text-white'>  Terms and Conditions  </Link>
              <span className='mx-3'>|</span>
              <Link to='/privacy-policy' className='has-text-white'>  Privacy Policy  </Link>
            </Column>
          </Columns>
        </Container> */}
      </footer>
    </div>
  );
};

export default Footer;
