import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import StarRatings from 'react-star-ratings';
import { Icon } from '@safelyq/bulma-ui-library';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const BusinessCardHoritonzal = ({ businessCardHorizontalProps, business }) => {
    const {
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        selectedConversationId,
        handleAppointments,
        handleMessaging,
        getFavoriteIconColor,
        showStatus,
        setShowStatus,
        setChatRights
    } = businessCardHorizontalProps;

    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

    const handleFavourite = (business, favBusinesses, setFavBusinesses, selectedConversationId) => {

        handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId);
        setShowStatus({ ...showStatus, favouriteBusiness: true, messaging: false, appointments: false }) // close all other sections
    }

    const handleBusinessSelected = (business) => {

        handleMessaging(business?.id);

        setChatRights({
            messagingAllowed: business?.messaging?.messagingAllowed,
            consumerInitiationAllowed: business?.messaging?.consumerInitiationAllowed,
            consumerResponseAllowed: business?.messaging?.consumerResponseAllowed,
            consumerAttachmentsAllowed: business?.messaging?.consumerAttachmentsAllowed,
        })
    }

    const { allMessages } = useSelector((state) => state.auth);

    const handleMessagingAllowed = () => {
        let conversationStarted = allMessages.find((chat) => chat?.business?.id === business?.id);

        // check if subscribed
        if (business?.businessSubscription?.subscriptionPlan?.features?.genericNumberMessagingAllowed) {
            // check if new conversation
            if (conversationStarted) {
                business?.messaging?.consumerResponseAllowed // check if response allowed
                    ?
                    handleBusinessSelected(business)
                    :
                    toast.warning("This business does not accept messages at this time");
            } else {
                business?.messaging?.consumerInitiationAllowed ? // check if initiation allowed
                    handleBusinessSelected(business)
                    :
                    toast.warning("This business does not accept messages at this time");
            }
        } else {
            toast.warning("This business does not accept messages at this time");
        }
    };

    return (

        // Previous fav business div
        <div
            className='card-custom-businesses mobile-business-card is-relative'
            key={business.id}
        >
            <CategoryTab className='is-hidden-mobile'>
                {business?.businessCategory?.name?.charAt(0).toUpperCase() + business?.businessCategory?.name?.slice(1) // Capitalize
                }
            </CategoryTab>
            <FavouriteButton className='is-hidden-mobile has-text-info is-clickable'>
                <Icon
                    onClick={() =>
                        // This is a universal Utility Function
                        handleFavourite(business, favBusinesses, setFavBusinesses, selectedConversationId)}
                    name={getFavoriteIconColor(business?.id)} collection='fa' />
            </FavouriteButton>

            <FavouriteButtonMobile className='is-hidden-desktop has-text-info is-clickable'>
                <Icon
                    style={{ fontSize: "12px" }}
                    onClick={() =>
                        // This is a universal Utility Function
                        handleFavourite(business, favBusinesses, setFavBusinesses, selectedConversationId)}
                    name={getFavoriteIconColor(business?.id)} collection='fa' />
            </FavouriteButtonMobile>


            <CategoryTabMobile className='is-hidden-desktop'>
                {business?.businessCategory?.name?.charAt(0).toUpperCase() + business?.businessCategory?.name?.slice(1) // Capitalize
                }
            </CategoryTabMobile>
            <img className='business-image-business-card'
                src={business?.picture?.path || emptyThumbnail}
                alt={business.name}
                onError={(e) => e.target.src = emptyThumbnail}
                style={{ width: "100%", objectFit: "cover" }} />
            <div className='px-2 my-2'>
                <Link to={{ pathname: `/business/${business.id}` }} className='is-size-7 has-text-weight-bold has-text-black' title={business?.name}    >
                    {business?.name?.length <= 20 ? business?.name : `${business?.name.substring(0, 20)}...`}
                </Link>
                <div className='is-flex mt-1'>
                    <i className="fa fa-quote-left favorite_icon mx-1 is-size-7 pt-2"
                        data-tip="Quotation"
                    />
                    <i className="fa fa-calendar favorite_icon mx-1 is-size-6-mobile is-size-6 pt-1"
                        onClick={() => handleAppointments(business?.id)}
                        data-tip="Appointments"
                    />
                    <a
                        className='p-0'
                        href={business?.email && `mailto:${business.email}?subject=Mail from ${business.name}`}
                        onClick={() => !business?.email && toast.warning('Email address is not available for this business.')}
                    >
                        <i className="fa fa-envelope favorite_icon mx-2 is-size-7"
                            data-tip="Mail"
                        />
                    </a>
                    <i className={`fa fa-comments favorite_icon mx-2 is-size-7 pt-2`}
                        onClick={handleMessagingAllowed}
                        data-tip="Messaging"
                    />
                </div>
                <ReactTooltip type='dark' effect='solid' />
            </div>
            <div className='px-2 row g-0 is-hidden-mobile'>
                <div className='col-lg-12'>
                    <p className="business_description is-size-7"> {business.description ? (`${business.description.substring(0, 20)}...`) : ("No Description")}</p>
                </div>
            </div>
            {business.address1 && (
                <div className='row px-2 g-0 is-hidden-mobile'>
                    <div className='col-lg-12'>
                        <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                        <span className='subtitle is-7'>  {business.city}  </span>
                    </div>
                </div>
            )}

            <div className='row px-2 g-0 is-hidden-mobile'>
                <div className='col-lg-12'>
                    <div style={{ color: 'rgb(26 17 186)' }}>
                        <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                        &nbsp;[  {business?.serviceRatings?.reviewsCount}]
                    </div>
                </div>
            </div>
        </div>
    )
}


export default BusinessCardHoritonzal;

const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 27px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const FavouriteButtonMobile = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 22px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
`;

const CategoryTab = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #009bc7;
  color: white;
  width: fit-content;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding-left: 7px;
  font-size: 14px;
  padding-right: 7px;
`;

const CategoryTabMobile = styled.div`
  position: absolute;
  top: 53px;
  left: 0;
  background: #009bc7;
  color: white;
  width: fit-content;
  height: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 5px;
  padding: 5px;
  font-size: 10px;
  padding-right: 7px;
`;
