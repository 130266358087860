import React, { useState } from 'react'
import { HeroSection, StyledMessageLoader } from '../ManageUserMessages.styled'
import MessageBoxMetaTags from '../MetaTags/MessageBoxMetaTags'
import { useLazyQuery } from '@apollo/client';
import { GET_BUSINESS_CONVERSATION } from '../requests';
import { useEffect } from 'react';
import MessageInput from './MessageInput/MessageInput';
import { useDispatch, useSelector } from 'react-redux';
import { updateSingleChatUnreadCount } from 'Redux/AuthSlice/AuthSlice';
import MessageHeader from './MessageHeader/MessageHeader';
import MessageBody from './MessageBody/MessageBody';
import NoChat from '../../../../../../assets/no_chat.png';
import { ClipLoader } from 'react-spinners';
import firestore from '@firebase';
import { useLocation } from 'react-router-dom';

const MessageBox = (props) => {
  const { businessIdFromSLB, selectedConversationId, chatRights, setChatRights } = props;


  const [allchats, setAllChats] = useState([])

  const { pathname } = useLocation();

  const [onGetBusinessConversation, { loading }] = useLazyQuery(GET_BUSINESS_CONVERSATION, { fetchPolicy: 'no-cache', });
  const global = useSelector(state => state)

  const { allMessages, userinfo } = useSelector((state) => state.auth);

  let activeChat = allMessages.find((chat) => chat.active)

  if (!activeChat) {
    activeChat = { business: { id: 0 } }
  }

  const dispatch = useDispatch()

  // refetch is called after the data message is sent
  const getNewMessage = async () => {

    try {
      const response = await onGetBusinessConversation({ variables: { businessId: activeChat.business.id, conversationId: pathname === "/search-business-ai" ? selectedConversationId : 0 } })

      setAllChats(response.data.getBusinessConversation.messages)
      if (response.data.getBusinessConversation.messages.length > 0) {
        const singleChat = response.data.getBusinessConversation.messages[0]
        setChatRights({
          messagingAllowed: singleChat.business.messaging.messagingAllowed,
          consumerResponseAllowed: singleChat.business.messaging.consumerResponseAllowed,
          consumerAttachmentsAllowed: singleChat.business.messaging.consumerAttachmentsAllowed,
        })
        dispatch(updateSingleChatUnreadCount({ businessid: response.data.getBusinessConversation.messages[0].business.id, unreadCount: response.data.getUser.user.conversationsSummary.unreadCount }))
      }
    } catch (error) {
    }
  }

  useEffect(() => {

    const unsubscribe = firestore.collection('chat').where('customerId', '==', `${userinfo.id}`).where('businessId', '==', `${activeChat?.business.id}`).onSnapshot(async querySnapshot => {

      // This fetches dynamic data from messages as well in chatslb
      if (String(pathname).includes('/messages') || String(pathname).includes('/search-business-ai')) {

        if (activeChat?.business.id) {
          // if called from chatslb page, than send conversation id else send null
          const response = await onGetBusinessConversation({ variables: { businessId: activeChat.business.id, conversationId: pathname === "/search-business-ai" ? selectedConversationId : 0 } })

          setAllChats(response?.data?.getBusinessConversation?.messages)
          if (response?.data?.getBusinessConversation?.messages?.length > 0) {
            const singleChat = response.data.getBusinessConversation.messages[0]
            const selectedConvoFromGlobalState = allMessages?.find(convoBusiness => convoBusiness?.business?.id === activeChat?.business.id)

            // Instead of getting chat rights from conversation, we fetch it while calling business
            // setChatRights({
            //   messagingAllowed: singleChat.business.messaging.messagingAllowed,
            //   consumerResponseAllowed: singleChat.business.messaging.consumerResponseAllowed,
            //   consumerAttachmentsAllowed: singleChat.business.messaging.consumerAttachmentsAllowed,
            // })

            // Fetch chat rights from global state

            pathname !== "/search-business-ai" && setChatRights({
              messagingAllowed: selectedConvoFromGlobalState?.business?.messaging?.messagingAllowed,
              consumerInitiationAllowed: selectedConvoFromGlobalState?.business?.messaging?.consumerInitiationAllowed,
              consumerResponseAllowed: selectedConvoFromGlobalState?.business?.messaging?.consumerResponseAllowed,
              consumerAttachmentsAllowed: selectedConvoFromGlobalState?.business?.messaging?.consumerAttachmentsAllowed,
            })

            dispatch(updateSingleChatUnreadCount({
              businessid: response.data.getBusinessConversation.messages[0].business.id,
              unreadCount: response.data.getUser.user.conversationsSummary.unreadCount
            }))
          }
        }
      }
    });
    return () => unsubscribe();
  }, [activeChat.business.id, dispatch, onGetBusinessConversation, pathname, userinfo.id])

  // Only call for ChatSLB - but not required as the api is called via sctive chat
  // useEffect(() => {
  //   const fetchBusinessMessages = async () => {
  //     if (pathname === "/search-business-ai") {

  //       try {

  //         const variables = {
  //           businessId: activeChat.business.id,
  //           conversationId: 0,
  //         };

  //         // If the current pathname is "/search-business-ai", add selectedConversationId to the variables
  //         if (pathname === "/search-business-ai") {
  //           variables.conversationId = selectedConversationId; // Assuming selectedConversationId comes from activeChat
  //         }

  //         // const response = await onGetBusinessConversation({ variables: { businessId: businessIdFromSLB } });
  //         const response = await onGetBusinessConversation({ variables });

  //         setAllChats(response.data.getBusinessConversation.messages);

  //         if (response.data.getBusinessConversation.messages.length > 0) {
  //           const singleChat = response.data.getBusinessConversation.messages[0];
  //           setChatRights({
  //             messagingAllowed: singleChat.business.messaging.messagingAllowed,
  //             consumerResponseAllowed: singleChat.business.messaging.consumerResponseAllowed,
  //             consumerAttachmentsAllowed: singleChat.business.messaging.consumerAttachmentsAllowed,
  //           });

  //           // dispatch(updateSingleChatUnreadCount({
  //           //   businessid: singleChat.business.id,
  //           //   unreadCount: response.data.getUser.user.conversationsSummary.unreadCount
  //           // }));
  //         }
  //       } catch (error) {
  //         console.error("Error fetching business conversation:", error);
  //       }
  //     }
  //   };

  //   fetchBusinessMessages();
  // }, [pathname, businessIdFromSLB]);


  return (
    <>
      <HeroSection style={{ scrollbarWidth: "none" }}>
        {
          activeChat.business.id ? (
            <>
              <MessageBoxMetaTags title={activeChat?.business.name} />
              <MessageHeader activeChat={activeChat} allchats={allchats} />
              {/* {loading ? <FullScreenLoader /> : <MessageBody allchats={allchats} />} */}
              <MessageBody allchats={allchats} loading={loading} />
              {loading && <StyledMessageLoader >

                <div className='container has-text-centered'>
                  <ClipLoader size='40' />
                  <br />
                  <small>Loading please wait...</small>
                </div>
              </StyledMessageLoader>}

              <MessageInput
                activeChat={activeChat}
                selectedConversationId={selectedConversationId}
                getNewMessage={getNewMessage}
                allowedMessage={chatRights.messagingAllowed}
                allowedResponse={chatRights.consumerResponseAllowed}
                allowedAttatchment={chatRights.consumerAttachmentsAllowed}
              />
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <img src={NoChat} alt="nochat" />
            </div>
          )
        }
      </HeroSection>
    </>

  )
}

export default MessageBox


const FullScreenLoader = () => (
  <section className='hero is-fullheight'>
    <div className='hero-body'>
      <div className='container has-text-centered'>
        <ClipLoader size='40' />
        <br />
        <small>Loading please wait...</small>
      </div>
    </div>
  </section>
);


