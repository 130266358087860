import React from 'react'
import { Modal, Title, Icon, Table, Label, Button } from '@safelyq/bulma-ui-library';
import ReactTooltip from 'react-tooltip';
import Overflow from 'react-overflow-indicator';

const DeleteConfirmationModal = ({ showDeleteModal, handleDeleteConversationConfirm }) => {
    return (
        <div>
            <Modal
                active={showDeleteModal}
            >
                <Modal.Background />
                <Modal.Card >
                    <Overflow style={{ overflow: "scroll" }}>
                        <Overflow.Content className='is-flex is-flex-direction-column'>
                            <Modal.Card.Body className=''>
                                <div className='mb-5 is-flex is-justify-content-space-between is-align-items-center'>
                                    <Title size='6' className='m-0'>
                                        Do you want to delete this conversation or simply detach it?
                                    </Title>

                                    <div>
                                        <Icon
                                            name='times'
                                            className='is-hoverable has-text-link'
                                            onClick={() => handleDeleteConversationConfirm("close")}
                                        />
                                    </div>
                                </div>

                                <div className='is-flex is-justify-content-end'>
                                    <Button
                                        className="mx-3"
                                        onClick={() => handleDeleteConversationConfirm("delink")}
                                    >
                                        Delink
                                    </Button>
                                    <Button
                                        onClick={() => handleDeleteConversationConfirm("delete")}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </Modal.Card.Body>
                        </Overflow.Content>
                    </Overflow>
                </Modal.Card>
                <ReactTooltip />
            </Modal>
        </div>
    )
}

export default DeleteConfirmationModal